<template>
	<div>
		<div class="grid grid-cols-2 mb-4">
			<div class="col-span-1">
				<h2 class="text-2xl font-bold">Company Applications</h2>
			</div>
			<div class="col-span-1">
				<div class="relative">
					<input
						type="search"
						name="search"
						class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-xs w-full"
						placeholder="Search for company’s name, status, etc."
						v-model="searchQuery"
					/>
					<ion-icon
						name="search-outline"
						class="text-blue-200 absolute z-10 text-lg ml-3 left-0"
						style="top: 50%; transform: translateY(-50%);"
					></ion-icon>
				</div>
			</div>
		</div>

		<div class="border border-solid border-blue-200">
			<div class="flex">
				<div
					class="duration-300"
					:class="{ 'w-full': !isOpen, 'w-1/2': isOpen }"
				>
					<datatable
						:index="true"
						:reverse-index="true"
						:data="companies"
						:columns="columns"
						:onClick="click"
						:query="searchQuery"
						:className="tableRowClassName"
						:loading="loading"
						ref="table"
					/>
				</div>
				<div class="w-1/2 px-8 py-10 border-l border-blue-200" v-if="isOpen">
					<div class="animated animation-delay-300 fadeIn relative z-10">
						<div class="flex flex-row items-center justify-between mb-5">
							<h2 class="text-xl font-bold">{{ company.company_name }}</h2>
							<div class="dropdown">
								<a href="#!" class="flex flex-col ml-3" dropdown>
									<div class="h-1 w-1 bg-gray-400 mb-1 rounded-full"></div>
									<div class="h-1 w-1 bg-gray-400 mb-1 rounded-full"></div>
									<div class="h-1 w-1 bg-gray-400 rounded-full"></div>
								</a>
								<ul class="dropdown-menu right whitespace-no-wrap">
									<a
										href="#!"
										class="dropdown-menu-item text-red-500"
										@click.prevent="deleteCompany(company)"
										>Delete Company</a
									>
								</ul>
							</div>
						</div>
					</div>

					<div class="h-full overflow-y-auto">
						<div class="animated animation-delay-300 fadeIn">
							<div
								class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 mb-10"
							>
								<div class="col-span-1">
									<div
										class="px-8 xl:px-8 py-8 border-t border-b border-l border-r md:border-r-0 lg:border-r xl:border-r-0 border-blue-200 relative"
									>
										<span
											class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-3"
											style="width: 3px"
										></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Company Email</div>
										</div>
										<div class="text-xs font-light">
											{{ company.company_email }}
										</div>
									</div>
								</div>

								<div class="col-span-1">
									<div
										class="px-8 xl:px-8 py-8 md:border-t lg:border-t-0 xl:border-t border-b border-l border-r border-blue-200 relative"
									>
										<span
											class="absolute h-4 bg-orange-500 block left-0 ml-3 xl:ml-3"
											style="width: 3px"
										></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Company Address</div>
										</div>
										<div class="text-xs font-light">
											{{ company.company_address }}
										</div>
									</div>
								</div>

								<div class="col-span-1">
									<div
										class="px-8 xl:px-8 py-8 border-b border-l border-r md:border-r-0 lg:border-r xl:border-r-0 border-blue-200 relative"
									>
										<span
											class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-3"
											style="width: 3px"
										></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Company Industry</div>
										</div>
										<div class="text-xs font-light">
											{{ company.company_industry }}
										</div>
									</div>
								</div>

								<div class="col-span-1">
									<div
										class="px-8 xl:px-8 py-8 border-b border-l border-r border-blue-200 relative"
									>
										<span
											class="absolute h-4 bg-orange-500 block left-0 ml-3 xl:ml-3"
											style="width: 3px"
										></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Company Website</div>
										</div>
										<div class="text-xs font-light">
											{{ company.company_website }}
										</div>
									</div>
								</div>

								<div class="col-span-1">
									<div
										class="px-8 xl:px-8 py-8 border-b border-l border-r md:border-r-0 lg:border-r xl:border-r-0 border-blue-200 relative"
									>
										<span
											class="absolute h-4 bg-blue-500 block left-0 ml-3 xl:ml-3"
											style="width: 3px"
										></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Total Employees</div>
										</div>
										<div class="text-xs font-light">
											{{ company.company_size }}
										</div>
									</div>
								</div>

								<div class="col-span-1">
									<div
										class="px-8 xl:px-8 py-8 border-b border-l border-r border-blue-200 relative"
									>
										<span
											class="absolute h-4 bg-orange-500 block left-0 ml-3 xl:ml-3"
											style="width: 3px"
										></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Agent</div>
										</div>
										<div class="text-xs font-light">
											{{ agentName || company.agent_code || "N/A" }}
										</div>
									</div>
								</div>

								<div class="col-span-1">
									<div
										class="px-8 xl:px-8 py-8 border-b border-l border-r md:border-r-0 lg:border-r xl:border-r-0 border-blue-200 relative"
									>
										<span
											class="absolute h-4 bg-green-500 block left-0 ml-3 xl:ml-3"
											style="width: 3px"
										></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Employee Name</div>
										</div>
										<div class="text-xs font-light">
											{{ company.employee_name }}
										</div>
									</div>
								</div>

								<div class="col-span-1">
									<div
										class="px-8 xl:px-8 py-8 border-b border-l border-r border-blue-200 relative"
									>
										<span
											class="absolute h-4 bg-blue-800 block left-0 ml-3 xl:ml-3"
											style="width: 3px"
										></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Employee Email</div>
										</div>
										<div class="text-xs font-light">
											{{ company.employee_email }}
										</div>
									</div>
								</div>

								<div class="col-span-1">
									<div
										class="px-8 xl:px-8 py-8 border-b border-l border-r md:border-r-0 lg:border-r xl:border-r-0 border-blue-200 relative"
									>
										<span
											class="absolute h-4 bg-green-500 block left-0 ml-3 xl:ml-3"
											style="width: 3px"
										></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Employee Phone</div>
										</div>
										<div class="text-xs font-light">
											{{ company.employee_phone }}
										</div>
									</div>
								</div>

								<div class="col-span-1">
									<div
										class="px-8 xl:px-8 py-8 border-b border-l border-r border-blue-200 relative"
									>
										<span
											class="absolute h-4 bg-blue-800 block left-0 ml-3 xl:ml-3"
											style="width: 3px"
										></span>
										<div class="flex justify-between items-center mb-4">
											<div class="text-xs">Employee Position</div>
										</div>
										<div class="text-xs font-light">
											{{ company.employee_designation }}
										</div>
									</div>
								</div>
							</div>

							<div class="flex flex-row justify-between items-center">
								<button
									type="button"
									class="button border border-red-500 text-red-500 hover:bg-red-500 hover:text-white"
									@click.prevent="decline"
									:disabled="form.loading"
								>
									<span v-if="form.loading">Loading...</span>
									<span v-else>Decline</span>
								</button>

								<button
									type="button"
									class="button bg-blue-500 text-white hover:bg-blue-500"
									@click.prevent="confirmApproval"
									:disabled="form.loading"
								>
									<span v-if="form.loading">Loading...</span>
									<span v-else>Approve</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<modal
			className="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
			ref="approveModal"
		>
			<img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark" />

			<div class="text-lg font-bold mb-4">
				Success!
			</div>
			<div class="text-xs mb-10">
				Company Approved Successfully. A email was sent to their HR to access
				the platform.
			</div>

			<router-link
				:to="{ name: 'dashboard' }"
				class="button bg-blue-500 hover:bg-blue-600 text-white"
			>
				Go to Dashboard
			</router-link>
		</modal>

		<modal
			className="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
			ref="declineModal"
		>
			<img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark" />

			<div class="text-lg font-bold mb-4">
				Success!
			</div>
			<div class="text-xs mb-10">
				Company Declined Successfully.
			</div>

			<router-link
				:to="{ name: 'dashboard' }"
				class="button bg-blue-500 hover:bg-blue-600 text-white"
			>
				Go to Dashboard
			</router-link>
		</modal>

		<modal className="w-full md:w-2/5 xl:w-3/10 px-10" ref="confirmModal">
			<h1 class="text-2xl font-bold mb-6">Onboard Company</h1>

			<div class="text-sm text-gray-500 mb-10">
				This company will be given access to use the Corporate Loans Platform.
				Select this company's tier and set their interest rate for their loan
				repayments and settlements.
			</div>

			<template v-if="getFormError(form)">
				<div class="alert alert-red-soft">
					<span class="alert-icon">!</span>
					<span class="text-xs font-normal">{{ getFormError(form) }}</span>
				</div>
			</template>

			<form @submit.prevent="approve">
				<form-group
					type="select"
					:options="tiers"
					left-icon="star-outline"
					name="tier"
					v-model="form.data.tier.value"
					:form="form"
				>
					Company Tier
				</form-group>
				<div class="flex flex-row items-center mb-10">
					<form-group
						type="number"
						class="mb-0"
						left-icon="cellular-outline"
						name="interest_rate"
						v-model="form.data.interest_rate.value"
						:form="form"
					>
						Interest Rate
					</form-group>
					<div class="text-sm text-gray-500">%/Month</div>
				</div>

				<button
					type="submit"
					class="button bg-blue-500 text-white hover:bg-blue-500"
					:disabled="form.loading"
				>
					<span v-if="form.loading">Loading...</span>
					<span v-else>Approve</span>
				</button>
			</form>
		</modal>
	</div>
</template>

<script>
export default {
	data() {
		return {
			searchQuery: "",
			checkmark: require("@/assets/checkmark-base.svg"),
			company: null,
			companies: [],
			columns: [
				{ name: "company_name", th: "Company Name" },
				{ name: "company_industry", th: "Company Industry" },
				{
					name: "company_size",
					th: "Company Size",
					render: (company, size) => `${size} Employees`,
				},
				{ name: "employee_name", th: "Employee Name" },
				{ name: "agent_code", th: "Agent Code" },
			],
			form: this.$options.basicForm(["interest_rate", "tier"]),
			loading: false,
			loadingCompany: false,
			tiers: [
				{ title: "Tier 1", value: 1 },
				{ title: "Tier 2", value: 2 },
				{ title: "Tier 3", value: 3 },
			],
		};
	},
	computed: {
		agentName() {
			return this.company?.agent?.name;
		},
		isOpen() {
			return !!this.company;
		},
		noOfStaff() {
			return this.company.profile?.no_of_staff
				? this.company.profile?.no_of_staff
				: 0;
		},
	},
	beforeMount() {
		this.getCompanies();
	},
	mounted() {
		this.$options.listenForClick.apply(this);
	},
	methods: {
		click(company) {
			this.company = this.company === company ? null : company;
			this.$refs.table.renderData();
		},
		confirmApproval() {
			this.$refs.confirmModal.open();
		},
		async getCompanies() {
			this.loading = true;
			await this.$get({
				url: `${this.$baseurl}/companies/requests`,
				headers: this.headers,
				success: (response) => {
					this.companies = response.data.requests;
				},
			});
			this.loading = false;
		},
		async approve() {
			this.form.error = false;
			this.form.loading = true;
			await this.$post({
				url: `${this.$baseurl}/companies/requests/approve/${this.company.id}`,
				data: {
					...this.getFormData(),
				},
				headers: this.headers,
				success: () => {
					this.$refs.approveModal.open();
					this.$refs.confirmModal.close();
					this.company = null;
					this.getCompanies();
				},
				error: (error) => {
					this.form.error = error;
					this.mapFormErrors(this.form, error.response?.data?.errors);
				},
			});
			this.form.loading = false;
		},
		async decline() {
			this.form.loading = true;
			await this.$post({
				url: `${this.$baseurl}/companies/requests/decline/${this.company.id}`,
				headers: this.headers,
				success: () => {
					this.$refs.declineModal.open();
					this.company = null;
					this.getCompanies();
				},
				error: (error) => {
					this.form.error = error;
				},
			});
			this.form.loading = false;
		},
		tableRowClassName(row) {
			return row.id === this.company?.id ? "selected" : "";
		},
		toUsers(company_id, company_name) {
			this.$router.push({
				name: "companies-users",
				params: { company_id },
				query: { company_name },
			});
		},
	},
};
</script>